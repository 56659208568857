import { AnalyticsInterface } from "../analytics";
import * as Sentry from "@sentry/react";
import { baseUrl } from "../../useApi";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { watchBlockNumber } from "@wagmi/core";
import { wagmiConfig } from "../../../config";

export class SentryAnalytics implements AnalyticsInterface {
  constructor() {
    if (!Sentry.isInitialized()) {
      Sentry.init({
        environment: process.env.NODE_ENV,
        enabled: !!process.env.REACT_APP_SENTRY_DNS,
        dsn: process.env.REACT_APP_SENTRY_DNS,
        tunnel: `${baseUrl}/tunnel`,
        integrations: [
          Sentry.browserProfilingIntegration(),
          Sentry.browserTracingIntegration(),
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          }),
          Sentry.replayIntegration({

          }),
          Sentry.replayCanvasIntegration(),
          Sentry.feedbackIntegration({
            colorScheme: "dark",
            autoInject: process.env.REACT_APP_SENTRY_ENABLE_FEEDBACK_FORM === 'true'
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.altitude\.fi/, /^https:\/\/(.+)amplifyapp\.com/],
        // Capture Replay for 100% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        normalizeDepth: 5
      });

      watchBlockNumber(wagmiConfig, {
        onBlockNumber(blockNumber) {
          if (Sentry.isInitialized()) {
            Sentry.setTag('block.number', blockNumber);
          }
        }
      })
    }
  }
  identify(id: string, data: object | undefined): void {
    Sentry.setUser({ id, ...data });
  }

  page(data: object | undefined): void {}

  track(eventId: string, data: object | undefined): void {
    Sentry.addBreadcrumb({
      category: "ui.event",
      data,
      level: "info",
    });
  }

}
