import { useSdk } from "../services/sdk-provider";
import { useVault } from "./useAppSelector";
import useSWR from "swr";
import { VaultName} from "@altitude-fi/altitude-v1-sdk/dist/types";
import { RateLimitResponse } from "@altitude-fi/altitude-v1-sdk/dist/generated-types/Ingress";
import { useBlockNumber } from "wagmi";
import {BigNumber} from "ethers";

export enum RateLimit {
  supply,
  borrow,
  claim
}

export const useVaultRateLimits = () => {
  const { sdk } = useSdk();
  const { data: vault } = useVault();
  const { data: blockNumber } = useBlockNumber();

  return useSWR<RateLimitResponse[]>(vault?.id ? ['VaultRateLimits', vault.id, blockNumber] : null, async ([, vaultId, blockNumber]) => {
    if (!vaultId) {
      return [];
    }
    const rateLimits = await sdk.getMultiData([
      sdk.multi.vaults[vaultId as VaultName].ingressControl.rateLimit(RateLimit.supply),
      sdk.multi.vaults[vaultId as VaultName].ingressControl.rateLimit(RateLimit.borrow),
      sdk.multi.vaults[vaultId as VaultName].ingressControl.rateLimit(RateLimit.claim),
    ]);

    return rateLimits.map((rateLimit) => {
      let available = rateLimit.available;
      if (rateLimit.period.gt(0)) {
        const blocksPassed = BigNumber.from(blockNumber).sub(rateLimit.updated);
        if (blocksPassed.gt(rateLimit.period)) {
          available = rateLimit.amount;
        } else {
          available = available.add(rateLimit.amount.mul(blocksPassed).div(rateLimit.period));
        }
      }
      if (available.gt(rateLimit.amount)) {
        available = rateLimit.amount;
      }
      return {
        ...rateLimit,
        available
      }
    });
  });
}
