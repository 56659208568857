import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextLink, TextHeader, TextParagraph, textSizes } from "../utils/styles/text";

import { showReportDialog, getClient } from '@sentry/react';
import { ThemeAppBackground } from "../utils/styles/theme.styles";
import { GridVars, ROUTES } from "../utils";
import { Button } from "../utils/styles/button";
import { LoaderInline } from "./index";

const ErrorPageWrapper = styled.div`
  background: ${ThemeAppBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  padding: 0 ${GridVars.padding.p5};
`;
const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  a {
    width: 100%;
  }
`;

const ErrorHeader = styled(TextHeader).attrs({ $size: 'larger' })`
  text-align: center;
  @media (max-width: ${GridVars.breakpoint.sm}) {
    font-size: ${textSizes.medium};
  }
`
const ErrorDescription = styled(TextParagraph)`
  text-align: center;
`

type ErrorPageProps = {
  eventId: string;
}

const ButtonLoader = () => (
  <LoaderInline height={12} loaderSettings={[
    {
      y: 0,
      x: "25%",
      rx: 3,
      ry: 3,
      width: "50%",
      height: 12,
    }
  ]} />
)
export const ErrorPage = ({ eventId }: ErrorPageProps) => {
  const [isSentryAvailable, setIsSentryAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const checkSentry = async () => {
      try {
        setIsLoading(true);
        // Check if Sentry was enabled and loaded
        const client = getClient();
        if (!client || !client.getOptions().enabled) {
          throw new Error('Sentry is disabled');
        }
        // Check if Sentry's "Crash Report" modal can be loaded.
        // If this request fails, it means Sentry is most likely blocked by ad blockers.
        await fetch('https://sentry.io/api/0/');
        setIsSentryAvailable(true);
      } catch (error) {
        setIsSentryAvailable(false);
      }
      setIsLoading(false);
    }
    checkSentry();
  }, []);
  return (
    <ErrorPageWrapper>
      <ErrorHeader>Oops! Something went wrong here.</ErrorHeader>
      <ErrorDescription>An unexpected error happened. If the problem persists please report it the Altitude team.</ErrorDescription>
      <ButtonContainer>
        {isLoading ? <ButtonLoader /> : (
          <>
            {isSentryAvailable && (
              <Button $size="compact" $variant="secondary" onClick={() => {
                showReportDialog({ eventId })
              }}>Report</Button>
            )}
            <TextLink href={ROUTES.dashboard}>Go back</TextLink>
          </>
        )}
      </ButtonContainer>
    </ErrorPageWrapper>
  )
}
